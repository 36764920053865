$primaryColor: #4e5788 !default;
$primaryLightColor: #5a6186 !default;
$primaryDarkColor: #4e5788c2 !default;
$primaryDarkerColor: #4e5788c5 !default;
$primaryTextColor: #ffffff !default;

$highlightBg: #eceff8 !default;
$highlightTextColor: $primaryDarkerColor !default;
$highlightFocusBg: rgba($primaryColor, 0.24) !default;

@import '../_variables';
@import './_fonts';
@import '../../../../theme-base/_components';
@import '../_extensions';
