@import 'Themes/themes/lara/lara-light/cyan/theme.scss';
@import 'primeicons/primeicons.css';
@import '/node_modules/primeflex/primeflex.css';

.iframe-container {
  //position: relative;
  ////overflow: hidden;
  margin: auto;
  width: 50vw;
  height: 70vh;
  //padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  //top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 80%;
  height: 80%;
}
